/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Link } from "gatsby"

interface BlogLinkProps {
  to: string
  children: React.ReactNode
}

const BlogLink = (props: BlogLinkProps): JSX.Element => {
  const { to, children } = props
  return (
    <Link
      sx={{
        display: "inline-block",
        textDecoration: "none",
        color: "text",
        "&:hover": { textDecoration: "underline" },
      }}
      to={to}
    >
      {children}
    </Link>
  )
}

export default BlogLink
