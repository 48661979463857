/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { CategoryInterface } from "../../types/category"
import BlogLink from "./BlogLink"
import { getCategoryLink } from "../../utils/category"

interface CategorySubLinkProps {
  category: CategoryInterface
}

const CategorySubLink = (props: CategorySubLinkProps) => {
  const { category } = props
  return (
    <BlogLink to={getCategoryLink(category)}>
      <span
        sx={{
          mr: 1,
          color: "accent",
          textTransform: "uppercase",
          fontSize:[0,1]
        }}
      >
        {category.name}
      </span>
    </BlogLink>
  )
}

export default CategorySubLink
