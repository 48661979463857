const getCategoryLink = category => {
  return `/blogCategory/${category.name.toLowerCase().replace(" ", "-")}`
}

const getCategories = data => {
  return data.allPrismicCategory.nodes.map(node => ({
    name: node.data.name,
    id: node.prismicId,
  }))
}

module.exports = {
  getCategoryLink,
  getCategories,
}
