/** @jsx jsx */
import React from "react"
import { Flex, Grid, Text, jsx } from "theme-ui"
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TelegramShareButton,
} from "react-share"
import FacebookIcon from "../../../images/facebook.svg"
import WhatsappIcon from "../../../images/whatsapp.svg"
import TwitterIcon from "../../../images/twitter.svg"
import LinkedInIcon from "../../../images/linkedin.svg"
import TelegramIcon from "../../../images/telegram.svg"
import { PostInterface } from "../../types/post"
import { getPostLink } from "../../utils/post"

interface SharePostProps {
  post: PostInterface
}

const SharePost = (props: SharePostProps): JSX.Element => {
  const { post } = props
  const url = "https://www.sarkariasacademy.com" + getPostLink(post)

  return (
    <Flex>
      <Text  sx={{fontSize:[0,1]}} variant="secondary" mr={3}>
        Share
      </Text>
      <Grid sx={{ width: "150px" }} columns={5} gap={1}>
        <a title="Share via Facebook">
          <FacebookShareButton hashtag="#sarkarIASAcademy" url={url}>
            <img sx={{ width: "20px" }} src={FacebookIcon} alt="facebook" />
          </FacebookShareButton>
        </a>
        <a title="Share via Whatsapp">
          <WhatsappShareButton url={url}>
            <img sx={{ width: "20px" }} src={WhatsappIcon} alt="whatsapp" />
          </WhatsappShareButton>
        </a>
        <a title="Share via Twitter">
          <TwitterShareButton url={url}>
            <img sx={{ width: "20px" }} src={TwitterIcon} alt="twitter" />
          </TwitterShareButton>
        </a>
        <a title="Share via LinkedIn">
          <LinkedinShareButton url={url}>
            <img sx={{ width: "20px" }} src={LinkedInIcon} alt="linkedIn" />
          </LinkedinShareButton>
        </a>
        <a title="Share via Telegram">
          <TelegramShareButton url={url} title={post.title}>
            <img sx={{ width: "20px" }} src={TelegramIcon} alt="telegram" />
          </TelegramShareButton>
        </a>
      </Grid>
    </Flex>
  )
}

export default SharePost
